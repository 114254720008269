import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Box,
  IconButton,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import * as CompanySelectors from "../../store/Companys/selectors";
import {
  addCompany,
  findCompanyByInn,
  getCompanyTypes,
  setCompanyTypes,
  setFindCompany,
  setUserEntry,
} from "../../store/Companys/actions";

function AddForm({ open, setOpen }) {
  const dispatch = useDispatch();

  const [title, setTitle] = useState("");
  const [inn, setInn] = useState("");
  const [kpp, setKpp] = useState("");
  const [address, setAddress] = useState("");
  const [type, setType] = useState(0);

  const [inner, setInner] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const companyType = useSelector(CompanySelectors.companyType);
  const findCompany = useSelector(CompanySelectors.findCompany);
  const user = useSelector(CompanySelectors.user);

  useEffect(() => {
    dispatch(getCompanyTypes());

    return () => {
      dispatch(setCompanyTypes([]));
    };
  }, []);

  useEffect(() => {
    if (inn.length >= 10) {
      dispatch(findCompanyByInn(inn));
    }
  }, [inn]);

  useEffect(() => {
    if (
      typeof findCompany.title !== "undefined" &&
      findCompany.title !== null
    ) {
      setTitle(findCompany.title);
      setKpp(findCompany.kpp);
      setAddress(findCompany.address);
      dispatch(setFindCompany([]));
    }
  }, [findCompany]);

  const handleClose = () => {
    setOpen(false);
    dispatch(setUserEntry([]));
  };

  const handleUpdate = () => {
    var data = {
      title,
      inn,
      kpp,
      address,
      type,
      inner,
      name,
      email,
      phone,
    };

    dispatch(addCompany(data));
    setTitle("");
    setInn("");
    setKpp("");
    setAddress("");
    setName("");
    setEmail("");
    setPhone("");
    setType(0);
    setInner(false);
  };

  return (
    <Dialog open={open} onClose={() => handleClose(false)}>
      <DialogTitle>
        Добавить
        <IconButton
          aria-label="close"
          onClick={() => handleClose(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <FormControl>
          <Box
            sx={{
              display: "flex",
            }}
          >
            <Box
              sx={{
                flex: 1,
              }}
            >
              <TextField
                margin="dense"
                label="Наименование"
                type="text"
                fullWidth
                variant="outlined"
                required={true}
                color="custom_purple"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
              <TextField
                margin="dense"
                label="ИНН"
                type="text"
                fullWidth
                variant="outlined"
                required={true}
                color="custom_purple"
                value={inn}
                onChange={(e) => setInn(e.target.value)}
              />
              <Typography sx={{ width: "200px", fontSize: 11 }}>
                Введите ИНН (10 цифр) для автозаполнения полей
              </Typography>
              <TextField
                margin="dense"
                label="КПП"
                type="text"
                fullWidth
                variant="outlined"
                color="custom_purple"
                value={kpp}
                onChange={(e) => setKpp(e.target.value)}
              />
              <TextField
                margin="dense"
                label="Адрес"
                type="text"
                fullWidth
                variant="outlined"
                color="custom_purple"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
              <TextField
                margin="dense"
                select
                label="Тип компании"
                value={type}
                onChange={(e) => setType(e.target.value)}
                SelectProps={{
                  native: true,
                }}
                variant="outlined"
              >
                <option value={0}>Выбрать</option>
                {companyType.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.title}
                  </option>
                ))}
              </TextField>
              <FormControlLabel
                control={
                  <Switch
                    color="custom_purple"
                    checked={inner}
                    onChange={() => setInner(!inner)}
                  />
                }
                label="Внутренняя"
              />
            </Box>
            <Box
              sx={{
                flex: 1,
                marginLeft: "24px",
              }}
            >
              <TextField
                margin="dense"
                label="ФИО Владельца"
                type="text"
                fullWidth
                variant="outlined"
                required={true}
                color="custom_purple"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <TextField
                margin="dense"
                label="Почта"
                type="text"
                fullWidth
                variant="outlined"
                required={true}
                color="custom_purple"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                margin="dense"
                label="Телефон"
                type="phone"
                fullWidth
                variant="outlined"
                color="custom_purple"
                required={true}
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </Box>
          </Box>
          {typeof user.login !== "undefined" ? (
            <Box
              sx={{
                background: "#219653",
                padding: "2px 14px",
                borderRadius: "4px",
                color: "#fff",
              }}
            >
              <p>Логин: {user.login}</p>
              <p>Пароль: {user.password}</p>
            </Box>
          ) : null}
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button color="custom_purple" onClick={() => handleUpdate(false)}>
          Добавить
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddForm;
