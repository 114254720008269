import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getCompany,
  getCompanyType,
  getCompanyTypes,
  setCompany,
  setCompanyType,
  setCompanyTypes,
  updateCompany,
} from "../../store/Companys/actions";
import * as CompanySelectors from "../../store/Companys/selectors";
import EditIcon from "@mui/icons-material/Edit";
import ClearIcon from "@mui/icons-material/Clear";

function Company() {
  const { id } = useParams();
  const dispatch = useDispatch();

  const company = useSelector(CompanySelectors.company);
  const type = useSelector(CompanySelectors.type);
  const companyType = useSelector(CompanySelectors.companyType);

  const [edit, setEdite] = useState(false);

  const [name, setName] = useState("");
  const [inn, setInn] = useState("");
  const [kpp, setKpp] = useState("");
  const [address, setAddress] = useState("");
  const [group, setGroup] = useState([]);
  const [sale, setSale] = useState("");

  useEffect(() => {
    dispatch(getCompany(id));
    dispatch(getCompanyType(id));
    dispatch(getCompanyTypes());
    return () => {
      dispatch(setCompany([]));
      dispatch(setCompanyType([]));
      dispatch(setCompanyTypes([]));
    };
  }, []);

  useEffect(() => {
    if (edit) {
      setName(company.title);
      setInn(company.inn);
      setKpp(company.kpp);
      setAddress(company.address);
      setSale(company.sale);
      const value = [];
      type.forEach((item) => {
        value.push(`${item.id}`);
      });
      setGroup(value);
    } else {
      setSale(0);
      setName("");
      setInn("");
      setKpp("");
      setAddress("");
      setGroup([]);
    }
  }, [edit]);

  const handleChangeMultiple = (event) => {
    const { options } = event.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    console.log(value);
    setGroup(value);
  };

  return (
    <>
      <TableContainer>
        <Table sx={{ width: "100%" }} aria-label="simple table">
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                Наименование
              </TableCell>
              <TableCell align="right">
                {!edit ? (
                  company.title
                ) : (
                  <TextField
                    margin="dense"
                    label="Наименование"
                    type="text"
                    fullWidth
                    variant="outlined"
                    required={true}
                    color="custom_purple"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                ИНН
              </TableCell>
              <TableCell align="right">
                {!edit ? (
                  company.inn
                ) : (
                  <TextField
                    disabled
                    readOnly={true}
                    margin="dense"
                    label="ИНН"
                    type="text"
                    fullWidth
                    variant="outlined"
                    required={true}
                    color="custom_purple"
                    value={inn}
                  />
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                КПП
              </TableCell>
              <TableCell align="right">
                {!edit ? (
                  company.kpp
                ) : (
                  <TextField
                    margin="dense"
                    label="КПП"
                    type="text"
                    fullWidth
                    variant="outlined"
                    required={true}
                    color="custom_purple"
                    value={kpp}
                    onChange={(e) => setKpp(e.target.value)}
                  />
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Адрес
              </TableCell>
              <TableCell align="right">
                {!edit ? (
                  company.address
                ) : (
                  <TextField
                    margin="dense"
                    label="Адрес"
                    type="text"
                    fullWidth
                    variant="outlined"
                    required={true}
                    color="custom_purple"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                КПП
              </TableCell>
              <TableCell align="right">
                {!edit ? (
                  company.kpp
                ) : (
                  <TextField
                    margin="dense"
                    label="КПП"
                    type="text"
                    fullWidth
                    variant="outlined"
                    required={true}
                    color="custom_purple"
                    value={kpp}
                    onChange={(e) => setKpp(e.target.value)}
                  />
                )}
              </TableCell>
            </TableRow>
            {!edit ? (
              <TableRow>
                <TableCell component="th" scope="row">
                  Группа
                </TableCell>
                <TableCell align="right">
                  {type.map((item, i) =>
                    i === 0 ? item.title : ` - ${item.title}`
                  )}
                </TableCell>
              </TableRow>
            ) : (
              <TableRow>
                <TableCell component="th" scope="row">
                  Группа
                </TableCell>
                <TableCell align="right">
                  <TextField
                    margin="dense"
                    select
                    fullWidth
                    label="Группа"
                    SelectProps={{
                      native: true,
                      multiple: true,
                    }}
                    variant="outlined"
                    value={group}
                    onChange={handleChangeMultiple}
                  >
                    {companyType.map((item, i) => (
                      <option key={item.id} value={item.id}>
                        {item.title}
                      </option>
                    ))}
                  </TextField>
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell component="th" scope="row">
                Скидка
              </TableCell>
              <TableCell align="right">
                {!edit ? (
                  company.sale
                ) : (
                  <TextField
                    margin="dense"
                    label="Скидка"
                    type="text"
                    fullWidth
                    variant="outlined"
                    color="custom_purple"
                    value={sale}
                    onChange={(e) => setSale(e.target.value)}
                  />
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      {!edit ? (
        <Button
          onClick={() => setEdite(!edit)}
          sx={{ marginTop: "24px" }}
          startIcon={<EditIcon />}
          variant="contained"
          color="custom_purple"
        >
          Редактировать
        </Button>
      ) : (
        <>
          <Button
            onClick={() => setEdite(!edit)}
            sx={{ marginTop: "24px" }}
            startIcon={<ClearIcon />}
            variant="contained"
            color="error"
          >
            Отмена
          </Button>
          <Button
            onClick={() => {
              dispatch(
                updateCompany(id, {
                  name,
                  kpp,
                  address,
                  group,
                  sale,
                })
              );
              setEdite(false);
            }}
            sx={{ marginTop: "24px", marginLeft: "24px" }}
            variant="contained"
            color="custom_purple"
          >
            Обновить
          </Button>
        </>
      )}
    </>
  );
}

export default Company;
