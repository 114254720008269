import React from 'react'
import * as CompanySelectors from "../../store/Companys/selectors"
import { useDispatch, useSelector } from 'react-redux'
import { Table } from '../../components'
import { Container } from '@mui/material'
import { useEffect } from 'react'
import { getRegReqests } from '../../store/Companys/actions'
import { useNavigate } from 'react-router-dom'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import HighlightOffIcon from '@mui/icons-material/HighlightOff'



function RegRequest() {
    const regReq = useSelector(CompanySelectors.regReq)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        dispatch(getRegReqests())
    }, [])

    const id = (item) => {
        return <span onClick={() => next(item)} style={{ cursor: 'pointer' }}>{item.id}</span>
    }

    const next = (item) => {
        navigate(`/reg-request/${item.id}`)
    }

    const succcess = (item) => {
        return item.success === 1 ? <CheckCircleRoundedIcon color="success" /> : (item.success === 2 ? <HighlightOffIcon color='error' /> : null)
    }

    const cols = [
        { title: "#", val: id },
        { title: "ИНН", code: 'inn' },
        { title: 'КПП', code: 'kpp' },
        { title: 'Адрес', code: 'address' },
        { title: 'ФИО', code: 'name' },
        { title: 'Email', code: 'email' },
        { title: 'Телефон', code: 'phone' },
        { title: 'Обработано', val: succcess },
    ]

    return (
        <Container maxWidth="xl" sx={{ mt: 2 }}>
            <Table cols={cols} data={regReq} />
        </Container>
    )
}

export default RegRequest