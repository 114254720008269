import { Box, List, ListItem, ListItemText, Typography, Divider, TextField, Button, IconButton } from '@mui/material'
import React, { useState } from 'react'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SettingsBox from '../../components/SettingsBox'
//import { addMail, destroyEmail, getEmails } from '../../store/Settings/actions'
//import * as SettingsSelectors from '../../store/Settings/selectors'

function Settings() {
    const dispatch = useDispatch()

    //const emails = useSelector(SettingsSelectors.emails)
    const emails = []
    const users = []

    const [mail, setMail] = useState('')

    useEffect(() => {
        //dispatch(getEmails())
    }, [])

    const handleaddMail = () => {
        if (mail !== '') {
            //dispatch(addMail(mail))
            setMail('')
        }
    }

    return (
        <Box sx={{ display: "flex", p: '24px' }}>
            <SettingsBox title='Уведомлять о "заявках на регистрацию" почта'>
                <List>
                    {
                        emails !== [] && emails.map(item => (
                            <ListItem key={item.id}>
                                <ListItemText primary={item.email} />
                                <IconButton color='error' onClick={() => { }}>
                                    <HighlightOffIcon />
                                </IconButton>
                            </ListItem>
                        ))
                    }
                </List>
                <Box sx={{ mt: 2 }}>
                    <TextField
                        fullWidth
                        label="Email"
                        value={mail}
                        onChange={(e) => setMail(e.target.value)}
                    />
                    <Button onClick={handleaddMail} variant="contained" sx={{ mt: 2 }}>Добавить</Button>
                </Box>
            </SettingsBox>
            <SettingsBox title='Уведомлять о "заявках на регистрацию" telegram bot'>
                <List>
                    {
                        emails !== [] && emails.map(item => (
                            <ListItem key={item.id}>
                                <ListItemText primary={item.email} />
                                <IconButton color='error' onClick={() => { }}>
                                    <HighlightOffIcon />
                                </IconButton>
                            </ListItem>
                        ))
                    }
                </List>
                <Box sx={{ mt: 2 }}>
                    <TextField
                        fullWidth
                        select
                        label="Пользователи"
                        SelectProps={{
                            native: true,
                        }}
                    >
                        <option value={0}>Выбрать</option>
                        {
                            users.map((item) => <option key={item.id} value={item.id}>{item.title}</option>)
                        }
                    </TextField>
                    <Button onClick={handleaddMail} variant="contained" sx={{ mt: 2 }}>Добавить</Button>
                </Box>
            </SettingsBox>
        </Box >
    )
}

export default Settings