import { Box, Divider, IconButton, List, ListItem, ListItemText, Typography } from '@mui/material'
import React, { Children } from 'react'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'


function SettingsBox({ title, children }) {
    const result = Children.toArray(children)
    console.log(result)
    return (
        <Box width={300} sx={{
            boxShadow: "4px 4px 8px 0px rgba(34, 60, 80, 0.2)",
            p: '20px',
            borderRadius: '4px',
            mb: 2,
            mr: 2
        }}>
            <Typography sx={{ fontWeight: '500', mb: 2 }} component="h3">{title}</Typography>
            <Divider />
            {
                result[0]
            }
            <Divider />
            <Box sx={{ mt: 2 }}>
                {
                    result[1]
                }
            </Box>
        </Box>
    )
}

export default SettingsBox