import {
  SET_COMPANY,
  SET_COMPANYS,
  SET_COMPANY_MACHINE,
  SET_COMPANY_ROLES,
  SET_COMPANY_TYPE,
  SET_COMPANY_USER,
  SET_MACHINE_CLASS,
  SET_MACHINE_GEN,
  SET_MACHINE_MODAL,
  SET_MACHINE_TYPE,
  SET_MACHINE_TYPES,
  SET_MACHINE_WORRANTY,
  SET_REG_REQUESTS,
  SET_ADD_MACHINE_REQUESTS,
  SET_MACHINES_WITHOUT_COMPANY,
  SET_SERCH_MACHINE,
  SET_USER_ENTRY,
  SET_FIND_COMPANY,
} from "./types";

const initialState = {
  companys: [],
  company: [],
  users: [],
  type: [],
  roles: [],
  machines: [],
  classList: [],
  modal: [],
  gen: [],
  worranty: [],
  machineType: [],
  companyType: [],
  user: [],
  machine: [],
  regReq: [],
  findCompany: [],
  addMachineRequests: [],
  machinesWithoutCompany: [],
};

export const CompanysReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COMPANYS:
      return { ...state, companys: action.payload };
    case SET_COMPANY:
      return { ...state, company: action.payload };
    case SET_COMPANY_USER:
      return { ...state, users: action.payload };
    case SET_COMPANY_TYPE:
      return { ...state, type: action.payload };
    case SET_COMPANY_ROLES:
      return { ...state, roles: action.payload };
    case SET_COMPANY_MACHINE:
      return { ...state, machines: action.payload };
    case SET_MACHINE_CLASS:
      return { ...state, classList: action.payload };
    case SET_MACHINE_MODAL:
      return { ...state, modal: action.payload };
    case SET_MACHINE_GEN:
      return { ...state, gen: action.payload };
    case SET_MACHINE_WORRANTY:
      return { ...state, worranty: action.payload };
    case SET_MACHINE_TYPE:
      return { ...state, machineType: action.payload };
    case SET_MACHINE_TYPES:
      return { ...state, companyType: action.payload };
    case SET_USER_ENTRY:
      return { ...state, user: action.payload };
    case SET_SERCH_MACHINE:
      return { ...state, machine: action.payload };
    case SET_REG_REQUESTS:
      return { ...state, regReq: action.payload };
    case SET_ADD_MACHINE_REQUESTS:
      return { ...state, addMachineRequests: action.payload };
    case SET_MACHINES_WITHOUT_COMPANY:
      return { ...state, machinesWithoutCompany: action.payload };
    case SET_FIND_COMPANY:
      return { ...state, findCompany: action.payload };
    default:
      return state;
  }
};
