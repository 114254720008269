import { Box, Button, Container, Dialog, DialogContent, DialogTitle, FormControl, Grid, IconButton, List, ListItem, ListItemText, MenuItem, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { createForRequest, getCompanyTypes, getRegReqests, rejectionRegReqests, setCompanyTypes } from '../../store/Companys/actions'
import * as CompanySelectors from "../../store/Companys/selectors"
import DescriptionIcon from '@mui/icons-material/Description'
import { toast } from 'react-toastify'
import CloseIcon from '@mui/icons-material/Close';



function RegRequestDetail() {
    const { id } = useParams()
    const regReq = useSelector(CompanySelectors.regReq)

    const dispatch = useDispatch()
    const navigate = useNavigate()


    const [type, setType] = useState(0)
    const [rejectionDialog, setRejectionDialog] = useState(false)

    const companyType = useSelector(CompanySelectors.companyType)

    useEffect(() => {
        dispatch(getRegReqests(id))
        dispatch(getCompanyTypes())

        return () => {
            dispatch(setCompanyTypes([]))
        }
    }, [])

    const handleCreateCompany = () => {
        if (type === 0) {
            toast.error('Не выбран тип компании')
            return false
        }

        dispatch(createForRequest(id, type))
    }

    const cause = [
        {
            id: 0,
            title: 'Запрашиваемые документы предоставлены не в полном объеме и/или предоставлены некорректно'
        },
        {
            id: 1,
            title: 'Предоставлены подложные документы'
        },
        {
            id: 2,
            title: 'Не обнаружена принадлежность организации к технике ДСТ-УРАЛ'
        },
        {
            id: 3,
            title: 'Организация не является Дилером ДСТ-Урал/Сервисным партнером ДСТ-Урал/Владельцем (эксплуатантом) техники ДСТ-Урал'
        },
        {
            id: 4,
            title: 'Организация добавлена в реестр недобросовестных контрагентов'
        },
        {
            id: 5,
            title: 'Техника относится к первому поколению и старше'
        }
    ]

    return (
        <Container maxWidth="xl" sx={{ mt: 2 }}>
            <Grid container columnSpacing={2}>
                <Grid item xs={8}>
                    <TableContainer>
                        <Table sx={{ width: '100%' }} aria-label="simple table">
                            <TableBody>
                                <TableRow>
                                    <TableCell component="th" scope="row">Наименование</TableCell>
                                    <TableCell align="right">{regReq.title}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">ИНН</TableCell>
                                    <TableCell align="right">{regReq.inn}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">КПП</TableCell>
                                    <TableCell align="right">{regReq.kpp}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">Адрес</TableCell>
                                    <TableCell align="right">{regReq.address}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">ФИО</TableCell>
                                    <TableCell align="right">{regReq.name}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">Email</TableCell>
                                    <TableCell align="right">{regReq.email}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">Телефон</TableCell>
                                    <TableCell align="right">{regReq.phone}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">uid</TableCell>
                                    <TableCell align="right">{typeof regReq.uid !== "undefined" && regReq.uid}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        {
                            regReq.success === 0 ? (
                                <>
                                    <TextField
                                        margin="dense"
                                        select
                                        label="Тип компании"
                                        value={type}
                                        onChange={e => setType(e.target.value)}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        variant="outlined"
                                    >
                                        <option value={0}>Выбрать</option>
                                        {
                                            companyType.map((item) => {
                                                if (item.id !== 1) return <option key={item.id} value={item.id}>{item.title}</option>
                                            })
                                        }
                                    </TextField>
                                    <p>Сформировать компанию на основе заявки:</p>
                                    <Box>
                                        <Button color="custom_purple" onClick={handleCreateCompany} variant="contained">Сформировать</Button>
                                        <Button color="custom_purple" variant="contained" sx={{
                                            ml: 2
                                        }}
                                            onClick={() => setRejectionDialog(true)}
                                        >Отказать</Button>
                                    </Box>
                                </>
                            ) : (regReq.success === 1 ? <Box sx={{
                                mt: 2,
                                fontSize: '20px',
                                color: "#2e7d32"
                            }}>Принята</Box> : (regReq.success === 2 ? <Box sx={{
                                mt: 2,
                                fontSize: '20px',
                                color: "#d32f2f"
                            }}>Отказано</Box> : null))
                        }
                    </TableContainer>
                </Grid>
                <Grid item xs={4}>
                    <Typography sx={{
                        mt: 2,
                        mb: 1,
                        fontWeight: '500'
                    }}>ПСМ:</Typography>
                    <Box sx={{ display: 'flex' }}>
                        {typeof regReq.files !== "undefined" && regReq.files.map(item => {
                            if (item.type === 'psm') return (
                                <Box key={item.id} sx={{ mr: '15px' }}>
                                    <a style={{
                                        display: 'block',
                                        color: '#5955b3',
                                        padding: '15px',
                                        boxShadow: '4px 4px 8px 0px rgba(34, 60, 80, 0.2)',
                                        borderRadius: '4px'
                                    }} href={`https://dstapi.ru/${item.path}`} target="_blank" rel="noreferrer">
                                        <DescriptionIcon />
                                    </a>
                                </Box>
                            )
                        })}
                    </Box>
                    <Typography sx={{
                        mt: 2,
                        mb: 1,
                        fontWeight: '500'
                    }}>Свидетельство о регистрации машины(трактора):</Typography>
                    <Box sx={{ display: 'flex' }}>
                        {typeof regReq.files !== "undefined" && regReq.files.map(item => {
                            if (item.type === 'sved') return (
                                <Box key={item.id} sx={{ mr: '15px' }}>
                                    <a style={{
                                        display: 'block',
                                        color: '#5955b3',
                                        padding: '15px',
                                        boxShadow: '4px 4px 8px 0px rgba(34, 60, 80, 0.2)',
                                        borderRadius: '4px'
                                    }} href={`https://dstapi.ru/${item.path}`} target="_blank" rel="noreferrer">
                                        <DescriptionIcon />
                                    </a>
                                </Box>
                            )
                        })}
                    </Box>
                </Grid>
            </Grid>
            <Dialog open={rejectionDialog} onClose={() => setRejectionDialog(false)}>
                <DialogTitle sx={{
                    display: 'flex',
                    alignItems: 'center'
                }}>
                    Укажите причину отказа
                    <IconButton
                        aria-label="close"
                        onClick={() => setRejectionDialog(false)}
                        sx={{
                            color: (theme) => theme.palette.grey[500],
                            ml: 'auto'
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <FormControl component="form" onSubmit={(e) => {
                        e.preventDefault()
                        const reason = e.target.elements.cause.value

                        dispatch(rejectionRegReqests(id, reason))
                    }} sx={{
                        pt: 1,
                        maxWidth: "400px"
                    }}>
                        <TextField
                            name="cause"
                            select
                            label="Причина"
                            defaultValue={0}
                        >
                            {cause.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.title}
                                </MenuItem>
                            ))}
                        </TextField>
                        <Button sx={{ mt: 2 }} type="submit" color="custom_purple" variant="contained">Отказать</Button>
                    </FormControl>
                </DialogContent>
            </Dialog>
        </Container>
    )
}

export default RegRequestDetail