import * as AuthSelector from "../store/Auth/selectors"
import { useSelector } from "react-redux"
import { setAuth, setToken } from "../store/Auth/actions"
import api from "./api"

const setup = (store) => {
    api.interceptors.request.use((config) => {
        const token = localStorage.getItem("token")
        if (token) {
            config.headers["Authorization"] = 'Bearer ' + token;
        }

        return config
    }, (error) => {
        return Promise.reject(error)
    })

    const { dispatch } = store;
    api.interceptors.response.use((response) => {
        return response
    }, async (error) => {
        const originalConfig = error.config

        if (originalConfig.url !== "/api/auth/login" && error.response) {
            if (error.response.status === 401 && !originalConfig._retry) {
                originalConfig._retry = true

                try {
                    const rs = await api.post("/api/auth/refresh-tokens");

                    const { access_token } = rs.data;

                    localStorage.setItem("token", access_token)
                    dispatch(setToken(access_token));

                    return api(originalConfig);
                } catch (_error) {
                    dispatch(setAuth(false))
                    localStorage.setItem("token", '')
                    return Promise.reject(_error);
                }
            }
        }
        return Promise.reject(error)
    })
}

export default setup;
