import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Box, IconButton, Switch, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import * as CompanySelectors from "../../store/Companys/selectors"
import { addCompany, getCompanys, getCompanyTypes, getSerchMachine, linkMachine, setCompanys, setCompanyTypes, setSerchMachine, setUserEntry } from '../../store/Companys/actions';
import moment from 'moment';

function AddForm({ open, setOpen, id }) {
    const dispatch = useDispatch()

    const [nomer, setNomer] = useState('')
    const [black_date, setData] = useState(moment().format("YYYY-MM-DD"))
    const [owner, setOwner] = useState(false)

    const machine = useSelector(CompanySelectors.machine)
    const companys = useSelector(CompanySelectors.companys)

    useEffect(() => {
        dispatch(getCompanys())

        return () => {
            dispatch(setCompanys([]))
        }
    }, [])

    useEffect(() => {
        if (nomer.length >= 4)
            dispatch(getSerchMachine(nomer))

        return () => {
            dispatch(setSerchMachine([]))
        }
    }, [nomer])

    useEffect(() => {
        if (typeof machine.nomer !== "undefined") {
            if (machine.date_startwork !== null && machine.warranty !== null) {
                const endDate = moment(machine.date_startwork).add(machine.warranty.month, 'month').format("YYYY-MM-DD")
                setData(endDate)
            }
        }
    }, [machine]);

    const handleClose = () => {
        setOpen(false);
        dispatch(setUserEntry([]))
    };

    const handleUpdate = () => {
        var data = {
            company_id: id,
            nomer,
            owner,
            black_date
        }

        dispatch(linkMachine(data))

        setNomer('')
        setData(moment().format("YYYY-MM-DD"))
        setOwner('')
        setOpen(false)
    }

    const findToArray = (array, id) => {
        const item = array.find(item => item.id === id)

        return item ? item : []
    }

    return (
        <Dialog open={open} onClose={() => handleClose(false)}>
            <DialogTitle>
                Добавить
                <IconButton
                    aria-label="close"
                    onClick={() => handleClose(false)}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <FormControl>
                    {
                        machine.length !== 0 ? <p style={{ color: "#219653" }}>Машина найдена</p> : (nomer.length >= 4 ? <p style={{ color: "#EB5757" }}>Машина не найдена</p> : '')
                    }
                    {
                        machine.owner === id ? <p style={{ color: "#219653" }}>Машина уже привязана</p> : ''
                    }
                    <TextField
                        margin="dense"
                        label="Номер"
                        type="number"
                        fullWidth
                        variant="outlined"
                        color="custom_purple"
                        value={nomer}
                        onChange={e => setNomer(e.target.value)}
                    />
                    <TextField
                        InputProps={{ inputProps: { min: moment().format("YYYY-MM-DD") } }}
                        margin="dense"
                        label="Дата окончания доступа"
                        type="date"
                        fullWidth
                        variant="outlined"
                        color="custom_purple"
                        value={black_date}
                        onChange={e => setData(e.target.value)}
                    />
                    <FormControlLabel control={<Switch color="custom_purple" checked={owner} onChange={() => setOwner(!owner)} />} label="Назначить владельцем" />
                    <p>Текущий владелец: {typeof machine.owner !== "undefined" ? findToArray(companys, machine.owner).title : "Не указан"}</p>
                </FormControl>
            </DialogContent>
            <DialogActions>
                {
                    machine.owner !== id ? <Button color="custom_purple" onClick={() => handleUpdate(false)}>Добавить</Button> : ''
                }
            </DialogActions>
        </Dialog>
    )
}

export default AddForm