import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormLabel, IconButton, RadioGroup, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'

function DialogAction({ title, text, agree, open, setOpen, action, timer = 0 }) {
    const handleClose = () => {
        setOpen(false)
    }

    const handleAction = () => {
        action()
        setOpen(false)
    }



    const TimerButton = () => {
        const [time, setTimer] = useState(timer)

        useEffect(() => {
            if (timer) {
                setTimer(timer)
            }
        }, [])

        useEffect(() => {
            if (timer) {
                const timers = time > 0 && setInterval(() => setTimer(time - 1), 1000);

                return () => clearInterval(timers);
            }
        }, [time]);

        return <Button disabled={time !== 0} onClick={handleAction} autoFocus>{agree} {time !== 0 ? time : ''}</Button>
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {text}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Отмена</Button>
                {timer !== 0 ? <TimerButton /> : <Button onClick={handleAction} autoFocus>{agree}</Button>}
            </DialogActions>
        </Dialog>
    )
}

export default DialogAction