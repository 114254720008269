export const companys = (state) => state.CompanysReducer.companys;
export const company = (state) => state.CompanysReducer.company;
export const users = (state) => state.CompanysReducer.users;
export const type = (state) => state.CompanysReducer.type;
export const roles = (state) => state.CompanysReducer.roles;
export const machines = (state) => state.CompanysReducer.machines;
export const classList = (state) => state.CompanysReducer.classList;
export const modal = (state) => state.CompanysReducer.modal;
export const gen = (state) => state.CompanysReducer.gen;
export const worranty = (state) => state.CompanysReducer.worranty;
export const machineType = (state) => state.CompanysReducer.machineType;
export const companyType = (state) => state.CompanysReducer.companyType;
export const user = (state) => state.CompanysReducer.user;
export const machine = (state) => state.CompanysReducer.machine;
export const regReq = (state) => state.CompanysReducer.regReq;
export const addMachineRequests = (state) =>
  state.CompanysReducer.addMachineRequests;
export const machinesWithoutCompany = (state) =>
  state.CompanysReducer.machinesWithoutCompany;
export const findCompany = (state) => state.CompanysReducer.findCompany;
