import { legacy_createStore as createStore, combineReducers, applyMiddleware } from "redux"
import thunk from "redux-thunk"

import { AuthReducer } from "./Auth/reducers"
import { CompanysReducer } from "./Companys/reducers"

const rootReducers = combineReducers({
    AuthReducer,
    CompanysReducer
})

export default createStore(rootReducers, applyMiddleware(thunk))