import { Box, Container } from '@mui/system'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Tab, Tabs } from '@mui/material';
import { useEffect, useState } from 'react';

function CompanyWrapper() {
    const { id } = useParams()
    const [value, setValue] = useState(`/company/${id}`)
    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        setValue(location.pathname)
    }, [])

    const handleChange = (event, newValue) => {
        setValue(newValue)

        navigate(newValue)
    }

    return (
        <Container maxWidth="xl">
            <Box sx={{ width: '100%' }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    textColor="secondary"
                    indicatorColor="secondary"
                >
                    <Tab value={`/company/${id}`} label="Общая информация" />
                    <Tab value={`/company/${id}/machines`} label="Машины" />
                    <Tab value={`/company/${id}/users`} label="Пользователи" />
                </Tabs>
            </Box>
            <Outlet />
        </Container>
    )
}

export default CompanyWrapper