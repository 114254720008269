import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Box,
  IconButton,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { addUser } from "../../store/Companys/actions";

function AddForm({ open, setOpen, company_id, role_id }) {
  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");

  const handleClose = () => {
    setOpen(false);
  };

  const handleAdd = () => {
    var data = {
      name,
      email,
      phone,
      password,
      company_id,
      role_id,
    };

    dispatch(addUser(data));
    setName("");
    setEmail("");
    setPhone("");
    setPassword("");
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={() => handleClose(false)}>
      <DialogTitle>
        Добавить
        <IconButton
          aria-label="close"
          onClick={() => handleClose(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <FormControl>
          <TextField
            margin="dense"
            label="ФИО"
            type="text"
            fullWidth
            variant="outlined"
            required={true}
            color="custom_purple"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Почта"
            type="text"
            fullWidth
            variant="outlined"
            required={true}
            color="custom_purple"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Телефон"
            type="text"
            fullWidth
            variant="outlined"
            color="custom_purple"
            required={true}
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Пароль"
            type="text"
            fullWidth
            variant="outlined"
            color="custom_purple"
            required={true}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {email.length > 0 ? (
            <Typography sx={{ fontSize: 11, width: "200px" }}>
              Предупреждение! Логин и пароль будут отправлены пользователю на
              почту:{" "}
              <Typography sx={{ fontWeight: 700, fontSize: 12 }}>
                {email}
              </Typography>
            </Typography>
          ) : (
            ""
          )}
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button color="custom_purple" onClick={() => handleAdd(false)}>
          Добавить
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddForm;
