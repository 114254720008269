import "./style.css"
import moment from "moment"
import { Table as TableMui, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import Paper from '@mui/material/Paper';

const Table = ({ cols, data, params, rows = 40, onRowClick = null }) => {

    const getParams = (vars, id) => {
        if (params[vars].length === 0) {
            return <span className="placeholder" />
        }
        if (id !== null) {
            const index = params[vars].find(item => item.id === id)
            if (index.name) {
                return index.name
            }
            return index.title
        } else {
            return "---"
        }
    }

    const Thead = () => {
        return cols.map((item, i) => <TableCell key={i}>{item.title}</TableCell>)
    }

    const Ceil = ({ ceil, col, item, index }) => {
        if (col.fun && col.type === "link" && col.val) {
            return (
                <TableCell>
                    <span className="span-link" onClick={() => col.fun(ceil)}>{col.val(item)}</span>
                </TableCell>
            )
        } else if (col.fun && col.type === "link") {
            return (
                <TableCell>
                    <span style={{ cursor: "pointer", color: "#5955b3", fontWeight: "600" }} className="span-link" onClick={() => col.fun(ceil)}>{ceil}</span>
                </TableCell>
            )
        } else if (col.vars) {
            return (
                <TableCell> {getParams(col.vars, ceil)}</TableCell>
            )
        } else if (col.type === "date") {
            return (
                <TableCell>{moment(ceil).format("DD.MM.YYYY")}</TableCell>
            )
        } else if (col.type === "time") {
            return (
                <TableCell>{moment(ceil).format("HH:mm:ss")}</TableCell>
            )
        } else if (col.type === "dateTime") {
            return (
                <TableCell>{moment(ceil).format("DD.MM.YYYY HH:mm:ss")}</TableCell>
            )
        } else if (col.val) {
            return (
                <TableCell>{col.val(item, index)}</TableCell>
            )
        } else {
            return (
                <TableCell>{ceil}</TableCell>
            )
        }
    }

    const Row = ({ item, onClick, index }) => {
        return (
            <TableRow
                onClick={(e) => {
                    e.stopPropagation()
                    if (onClick !== null) onClick(item, index)
                }}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                {cols.map((col, i) => <Ceil key={i} index={index} ceil={item[col.code]} col={col} item={item} />)}
            </TableRow>
        )
    }

    const Rows = () => {
        return data.map((item, i) => <Row key={i} index={i} item={item} onClick={onRowClick} />)
    }

    return (
        <TableContainer component={Paper}>
            <TableMui aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <Thead />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.length > 0 ? <Rows /> : <></>}
                </TableBody>
            </TableMui>
        </TableContainer>
    )
}

export default Table