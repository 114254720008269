import React, { useState } from "react";
import {
  AppBar,
  Box,
  Button,
  Container,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  SvgIcon,
  Toolbar,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AccountCircle } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { logOut } from "../../store/Auth/actions";
import * as AuthSelector from "../../store/Auth/selectors";
import MenuIcon from "@mui/icons-material/Menu";

function Header() {
  const [anchorEl, setAnchorEl] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const auth = useSelector(AuthSelector.auth);
  const user = useSelector(AuthSelector.user);
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const menu = [
    {
      text: "Компании",
      url: "/",
    },
    {
      text: "Заявки на регистрацию",
      url: "/reg-request",
    },
    {
      text: "Заявки на добавление машины",
      url: "/add-machine-requests",
    },
    {
      text: "Настройки",
      url: "/setting",
    },
  ];

  const link = (url) => {
    navigate(url);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const _logOut = () => {
    handleClose();
    dispatch(logOut());
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const container =
    window !== undefined ? () => window.document.body : undefined;

  return (
    <AppBar position="static" color="inherit">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "Roboto",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
              color: "#5955b3",
            }}
          >
            CE
          </Typography>
          {auth && (
            <>
              <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleDrawerToggle}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
                <Box component="nav">
                  <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                      keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                      display: { xs: "block", sm: "none" },
                      "& .MuiDrawer-paper": {
                        boxSizing: "border-box",
                        width: 240,
                      },
                    }}
                  >
                    <List onClick={handleDrawerToggle}>
                      {menu.map((page) => (
                        <ListItem key={page.text} disablePadding>
                          <ListItemButton onClick={() => link(page.url)}>
                            <ListItemText primary={page.text} />
                          </ListItemButton>
                        </ListItem>
                      ))}
                    </List>
                  </Drawer>
                </Box>
              </Box>
              <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
                {menu.map((page) => (
                  <Button
                    key={page.text}
                    onClick={() => link(page.url)}
                    sx={{
                      my: 2,
                      display: "block",
                      color: "#5955b3",
                      fontWeight: "400",
                    }}
                  >
                    {page.text}
                  </Button>
                ))}
              </Box>
              <div>
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span
                    style={{
                      color: "#5955b3",
                    }}
                  >
                    {user.name}
                  </span>
                  <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu}
                  >
                    <AccountCircle sx={{ color: "#5955b3" }} />
                  </IconButton>
                </Typography>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem>Профиль</MenuItem>
                  <MenuItem onClick={_logOut}>Выход</MenuItem>
                </Menu>
              </div>
            </>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Header;
