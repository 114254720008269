import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { Wrapper } from "./components";
import {
  AddMachineRequest,
  Auth,
  Company,
  Companys,
  CompanyWrapper,
  Machines,
  RegRequest,
  Settings,
  Users,
} from "./screens";
import { getUser, setAuth } from "./store/Auth/actions";
import * as AuthSelector from "./store/Auth/selectors";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RegRequestDetail from "./screens/RegRequest/RegRequestDetail";
import AddMachineRequestDetail from "./screens/AddMachineRequest/AddMachineRequestDetail";

function App() {
  const auth = useSelector(AuthSelector.auth);
  const token = useSelector(AuthSelector.token);

  const dispatch = useDispatch();

  useEffect(() => {
    if (token) {
      dispatch(getUser());
    } else {
      dispatch(setAuth(false));
    }
  }, [auth]);

  if (auth === null) return <></>;

  return (
    <Wrapper>
      {auth ? (
        <Routes>
          <Route path="/" element={<Companys />} />
          <Route path="/company" element={<CompanyWrapper />}>
            <Route path=":id" element={<Company />} />
            <Route path=":id/machines" element={<Machines />} />
            <Route path=":id/users" element={<Users />} />
          </Route>
          <Route path="/reg-request" element={<RegRequest />} />
          <Route path="/reg-request/:id" element={<RegRequestDetail />} />
          <Route path="/add-machine-requests" element={<AddMachineRequest />} />
          <Route
            path="/add-machine-requests/:id"
            element={<AddMachineRequestDetail />}
          />
          <Route path="/setting" element={<Settings />} />
        </Routes>
      ) : (
        <Auth />
      )}
      <ToastContainer position="bottom-right" />
    </Wrapper>
  );
}

export default App;
