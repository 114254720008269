import {
  Autocomplete,
  Box,
  Button,
  Container,
  createFilterOptions,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  addMachineForCompany,
  createForRequest,
  getAddMachineRequests,
  getCompanyTypes,
  getMachinesWithoutCompany,
  getRegReqests,
  rejectionAddMachineReqest,
  rejectionRegReqests,
  setCompanyTypes,
  setMachinesWithoutCompany,
} from "../../store/Companys/actions";
import * as CompanySelectors from "../../store/Companys/selectors";
import DescriptionIcon from "@mui/icons-material/Description";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import AddIcon from "@mui/icons-material/Add";
import DelIcon from "@mui/icons-material/Remove";

function AddMachineRequestDetail() {
  const { id } = useParams();
  const addMachineRequest = useSelector(CompanySelectors.addMachineRequests);

  const dispatch = useDispatch();

  const [countMachines, setCountMachines] = useState(1);
  const [machines, setMachines] = useState([]);
  const [nomer, setNomer] = useState(null);
  const [owner, setOwner] = useState(false);
  const [rejectionDialog, setRejectionDialog] = useState(false);
  const [aproveDialog, setAproveDialog] = useState(false);

  const machinesWithoutCompany = useSelector(
    CompanySelectors.machinesWithoutCompany
  );

  useEffect(() => {
    dispatch(getAddMachineRequests(id));
    dispatch(getMachinesWithoutCompany());

    return () => {
      dispatch(setMachinesWithoutCompany([]));
    };
  }, []);

  const handleAddMachine = () => {
    setAproveDialog(false);

    if (nomer === null || nomer === 0) {
      toast.error("Не выбрана последняя машина");
      return false;
    }

    const nullEl = machines.find(
      (item) =>
        typeof item.nomer === "undefined" ||
        item.nomer === null ||
        item.nomer === 0
    );

    if (nullEl) {
      toast.error("Не выбрана машина");
      return false;
    }

    var data = {
      company_id: addMachineRequest.company_id,
      arMachines: [
        ...machines,
        {
          nomer,
          owner,
          black_date: moment().add(1, "year").format("YYYY-MM-DD"),
        },
      ],
    };

    dispatch(addMachineForCompany(id, data));
  };

  const cause = [
    {
      id: 0,
      title:
        "Запрашиваемые документы предоставлены не в полном объеме и/или предоставлены некорректно",
    },
    {
      id: 1,
      title: "Предоставлены подложные документы",
    },
    {
      id: 2,
      title: "Не обнаружена принадлежность организации к технике ДСТ-УРАЛ",
    },
    {
      id: 3,
      title:
        "Организация не является Дилером ДСТ-Урал/Сервисным партнером ДСТ-Урал/Владельцем (эксплуатантом) техники ДСТ-Урал",
    },
    {
      id: 4,
      title: "Организация добавлена в реестр недобросовестных контрагентов",
    },
    {
      id: 5,
      title: "Техника относится к первому поколению и старше",
    },
  ];

  function LooperFormGroup() {
    const keys = [...Array(countMachines).keys()];
    return (
      <div>
        {keys.map((idx) => (
          <FormGroup key={idx}>
            <Autocomplete
              options={machinesWithoutCompany.map((item) =>
                item.nomer.toString()
              )}
              value={
                typeof machines[idx] !== "undefined"
                  ? machines[idx].nomer
                  : nomer
              }
              disabled={typeof machines[idx] !== "undefined" ? true : false}
              onChange={(e, newValue) => {
                if (typeof machines[idx] === "undefined") setNomer(newValue);
              }}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Свободные машины"
                  variant="outlined"
                  margin="dense"
                />
              )}
              filterOptions={createFilterOptions({
                matchFrom: "start",
                stringify: (option) => option,
              })}
            />
            <FormControlLabel
              sx={{ margin: "10px 0" }}
              control={
                <Switch
                  color="custom_purple"
                  disabled={typeof machines[idx] !== "undefined" ? true : false}
                  checked={
                    typeof machines[idx] !== "undefined"
                      ? machines[idx].owner
                      : owner
                  }
                  onChange={() => {
                    if (typeof machines[idx] === "undefined") setOwner(!owner);
                  }}
                />
              }
              label="Назначить владельцем"
            />
          </FormGroup>
        ))}
      </div>
    );
  }

  return (
    <Container maxWidth="xl" sx={{ mt: 2 }}>
      <Grid container columnSpacing={2}>
        <Grid item xs={8}>
          <TableContainer>
            <Table sx={{ width: "100%" }} aria-label="simple table">
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Наименование
                  </TableCell>
                  <TableCell align="right">
                    {addMachineRequest.company_title}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    ИНН
                  </TableCell>
                  <TableCell align="right">
                    {addMachineRequest.company_inn}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    КПП
                  </TableCell>
                  <TableCell align="right">
                    {addMachineRequest.company_kpp}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Адрес
                  </TableCell>
                  <TableCell align="right">
                    {addMachineRequest.company_address}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    ФИО
                  </TableCell>
                  <TableCell align="right">
                    {addMachineRequest.user_name}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Email
                  </TableCell>
                  <TableCell align="right">
                    {addMachineRequest.user_email}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Телефон
                  </TableCell>
                  <TableCell align="right">
                    {addMachineRequest.user_phone}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            {addMachineRequest.success === 0 ? (
              <>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    gap: "30px",
                  }}
                >
                  <Box>
                    <LooperFormGroup />
                  </Box>
                  <Box sx={{ mt: "20px" }}>
                    <Button
                      color="custom_purple"
                      aria-label="add"
                      size="small"
                      disabled={countMachines >= 10 ? true : false}
                      onClick={() => {
                        if (nomer === null || nomer === 0) {
                          toast.error("Необходимо заполнить имеющиеся поля");
                          return;
                        }
                        machines.push({
                          nomer,
                          owner,
                          black_date: moment()
                            .add(1, "year")
                            .format("YYYY-MM-DD"),
                        });
                        setCountMachines(countMachines + 1);
                        setNomer(null);
                        setOwner(false);
                      }}
                    >
                      <AddIcon />
                    </Button>
                    <Button
                      color="custom_purple"
                      aria-label="del"
                      size="small"
                      disabled={countMachines <= 1 ? true : false}
                      onClick={() => {
                        setCountMachines(countMachines - 1);
                        setNomer(machines[countMachines - 2].nomer);
                        setOwner(machines[countMachines - 2].owner);
                        machines.pop();
                      }}
                    >
                      <DelIcon />
                    </Button>
                  </Box>
                  <Box>
                    <p>Добавить машины к компании по заявке:</p>
                    <Box>
                      <Button
                        color="custom_purple"
                        onClick={(e) => {
                          if (nomer === null || nomer === 0) {
                            toast.error("Необходимо заполнить последнее поле");
                            return;
                          }
                          setAproveDialog(true);
                        }}
                        variant="contained"
                      >
                        Сформировать
                      </Button>
                      <Button
                        color="custom_purple"
                        variant="contained"
                        sx={{
                          ml: 2,
                        }}
                        onClick={() => setRejectionDialog(true)}
                      >
                        Отказать
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </>
            ) : addMachineRequest.success === 1 ? (
              <Box
                sx={{
                  mt: 2,
                  fontSize: "20px",
                  color: "#2e7d32",
                }}
              >
                Принята
              </Box>
            ) : addMachineRequest.success === 2 ? (
              <Box
                sx={{
                  mt: 2,
                  fontSize: "20px",
                  color: "#d32f2f",
                }}
              >
                Отказано
              </Box>
            ) : null}
          </TableContainer>
        </Grid>
        <Grid item xs={4}>
          <Typography
            sx={{
              mt: 2,
              mb: 1,
              fontWeight: "500",
            }}
          >
            ПСМ:
          </Typography>
          <Box sx={{ display: "flex" }}>
            {typeof addMachineRequest.files !== "undefined" &&
              addMachineRequest.files.map((item) => {
                if (item.type === "psm")
                  return (
                    <Box key={item.id} sx={{ mr: "15px" }}>
                      <a
                        style={{
                          display: "block",
                          color: "#5955b3",
                          padding: "15px",
                          boxShadow: "4px 4px 8px 0px rgba(34, 60, 80, 0.2)",
                          borderRadius: "4px",
                        }}
                        href={`https://dstapi.ru/${item.path}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <DescriptionIcon />
                      </a>
                    </Box>
                  );
              })}
          </Box>
          <Typography
            sx={{
              mt: 2,
              mb: 1,
              fontWeight: "500",
            }}
          >
            Свидетельство о регистрации машины(трактора):
          </Typography>
          <Box sx={{ display: "flex" }}>
            {typeof addMachineRequest.files !== "undefined" &&
              addMachineRequest.files.map((item) => {
                if (item.type === "sved")
                  return (
                    <Box key={item.id} sx={{ mr: "15px" }}>
                      <a
                        style={{
                          display: "block",
                          color: "#5955b3",
                          padding: "15px",
                          boxShadow: "4px 4px 8px 0px rgba(34, 60, 80, 0.2)",
                          borderRadius: "4px",
                        }}
                        href={`https://dstapi.ru/${item.path}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <DescriptionIcon />
                      </a>
                    </Box>
                  );
              })}
          </Box>
        </Grid>
      </Grid>
      <Dialog open={aproveDialog} onClose={() => setAproveDialog(false)}>
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          Будут добавлены следующие машины:
          <IconButton
            aria-label="close"
            onClick={() => setAproveDialog(false)}
            sx={{
              color: (theme) => theme.palette.grey[500],
              ml: "auto",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <ol>
            {typeof machines !== "undefined" &&
              machines.length > 0 &&
              machines.map((machine) => (
                <li>
                  Номер: <b>{machine.nomer}</b>. Владелец:{" "}
                  {machine.owner ? "+" : "-"}
                </li>
              ))}
            <li>
              Номер: <b>{nomer}</b>. Владелец: {owner ? "+" : "-"}
            </li>
          </ol>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ mt: 2 }}
            color="custom_purple"
            variant="contained"
            onClick={(e) => setAproveDialog(false)}
          >
            Отменить
          </Button>
          <Button
            sx={{ mt: 2 }}
            onClick={handleAddMachine}
            color="custom_purple"
            variant="contained"
          >
            Записать
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={rejectionDialog} onClose={() => setRejectionDialog(false)}>
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          Укажите причину отказа
          <IconButton
            aria-label="close"
            onClick={() => setRejectionDialog(false)}
            sx={{
              color: (theme) => theme.palette.grey[500],
              ml: "auto",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <FormControl
            component="form"
            onSubmit={(e) => {
              e.preventDefault();
              const reason = e.target.elements.cause.value;

              dispatch(rejectionAddMachineReqest(id, reason));
              setRejectionDialog(false);
            }}
            sx={{
              pt: 1,
              maxWidth: "400px",
            }}
          >
            <TextField name="cause" select label="Причина" defaultValue={0}>
              {cause.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.title}
                </MenuItem>
              ))}
            </TextField>
            <Button
              sx={{ mt: 2 }}
              type="submit"
              color="custom_purple"
              variant="contained"
            >
              Отказать
            </Button>
          </FormControl>
        </DialogContent>
      </Dialog>
    </Container>
  );
}

export default AddMachineRequestDetail;
