import { Button, Checkbox, Fab, FormControlLabel, FormLabel, Grid, Radio, RadioGroup } from '@mui/material'
import { Box, Container } from '@mui/system'
import React, { useEffect, useState } from 'react'
import AddIcon from '@mui/icons-material/Add'
import * as CompanySelectors from "../../store/Companys/selectors"
import { useDispatch, useSelector } from 'react-redux'
import { getCompanys, getCompanyTypes, setCompanys, setCompanyTypes } from '../../store/Companys/actions'
import { Table } from '../../components';
import CircleIcon from '@mui/icons-material/Circle'
import { useNavigate } from 'react-router-dom'
import AddForm from "./AddForm"

function Companys() {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [add, setAdd] = useState(false)
    const [radio, setRadio] = useState(0)

    const companys = useSelector(CompanySelectors.companys)
    const companyType = useSelector(CompanySelectors.companyType)

    useEffect(() => {
        dispatch(getCompanys())
        dispatch(getCompanyTypes())

        return () => {
            dispatch(setCompanys([]))
            dispatch(setCompanyTypes([]))
        }
    }, [])

    useEffect(() => {
        dispatch(getCompanys(radio))
    }, [radio])

    const inn = (item) => {
        return <span onClick={() => next(item)} style={{ cursor: 'pointer' }}>{item.inn}</span>
    }

    const next = (item) => {
        navigate(`/company/${item.id}`)
    }

    const tabCols = [
        { title: "inn", val: inn },
        { title: "kpp", code: 'kpp' },
        { title: "Наименование", code: 'title' },
        { title: "Внутренняя", code: 'inner' }
    ]

    const tabParams = {

    }

    return (
        <Grid container
            sx={{
                height: '100%',
                margin: 0,
                padding: '24px'
            }}
        >
            <Grid item xs={2} sx={{
                height: '100%'
            }}>
                <Box
                    sx={{
                        background: "#f5f6fa",
                        padding: "20px 24px",
                        marginRight: '24px',
                        borderRadius: '4px'
                    }}
                >
                    <FormLabel id="radio-buttons-group-label">Тип</FormLabel>
                    <RadioGroup
                        aria-labelledby="radio-buttons-group-label"
                        defaultValue="female"
                        name="radio-buttons-group"
                    >
                        <FormControlLabel value={0} control={<Radio color='custom_purple' checked={radio === 0} onClick={() => setRadio(0)} />} label="Все" />
                        {
                            companyType.map(item => <FormControlLabel key={item.id} value={item.id} control={<Radio color='custom_purple' checked={radio === item.id} onClick={() => setRadio(item.id)} />} label={item.title} />)
                        }
                    </RadioGroup>
                </Box>
            </Grid>
            <Grid item xs={10} sx={{
                height: '100%'
            }}>
                <Box maxWidth="xl" sx={{
                    height: '100%',
                    overflowY: 'scroll',
                    '::-webkit-scrollbar': {
                        width: '8px'
                    },
                    '::-webkit-scrollbar-track': {
                        background: '#EEEEEE',
                        borderRadius: '10px'
                    },
                    '::-webkit-scrollbar-thumb': {
                        backgroundColor: '#5955b3',
                        borderRadius: '10px'
                    }
                }}>
                    <Table cols={tabCols} data={companys} params={tabParams} rows={40} />
                    <Fab color='custom_purple' aria-label="add" sx={{
                        position: 'fixed',
                        bottom: '50px',
                        right: '50px'
                    }}
                        onClick={() => setAdd(true)}
                    >
                        <AddIcon />
                    </Fab>
                </Box>
            </Grid>
            <AddForm open={add} setOpen={setAdd} />
        </Grid>
    )
}

export default Companys