import { Accordion, AccordionDetails, AccordionSummary, Button, Collapse, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Table } from '../../components'
import { getCompanyType, getCompanyUser, setCompanyType, setCompanyUser, getCompanyRoles, setCompanyRoles } from '../../store/Companys/actions'
import * as CompanySelectors from "../../store/Companys/selectors"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import AddForm from './AddForm'

function Users() {
    const { id } = useParams()
    const dispatch = useDispatch()

    const [expanded, setExpanded] = useState(false)

    const users = useSelector(CompanySelectors.users)
    const type = useSelector(CompanySelectors.type)
    const roles = useSelector(CompanySelectors.roles)

    const [modal, setModal] = useState(false);
    const [role_id, setRoleId] = useState(0);

    useEffect(() => {
        dispatch(getCompanyType(id))
        dispatch(getCompanyRoles(id))

        return () => {
            dispatch(setCompanyUser([]))
            dispatch(setCompanyType([]))
            dispatch(setCompanyRoles([]))
        }
    }, [])


    const handleChange = (panel, role_id) => (event, isExpanded) => {
        dispatch(setCompanyUser([]))
        if (isExpanded) {
            dispatch(getCompanyUser(id, role_id))
        }

        setExpanded(isExpanded ? panel : false);
    };

    const tabCols = [
        { title: "Имя", code: 'name' },
        { title: "Почта", code: 'email' },
        { title: "Телефон", code: 'phone' }
    ]

    const tabParams = {

    }

    return (
        <>
            <h3>Группа: {
                type.map((item, i) => (
                    i === 0 ? item.title : ` - ${item.title}`
                ))
            }</h3>
            {
                roles.map((item, i) => {
                    return (
                        <Accordion key={item.id} expanded={expanded === `panel${i}`} onChange={handleChange(`panel${i}`, item.id)}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                                <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                    {item.title}
                                </Typography>
                                <Typography sx={{ color: 'text.secondary' }}>{item.comment}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Collapse in={true} timeout="auto" unmountOnExit>
                                    <Table cols={tabCols} data={users} params={tabParams} rows={40} />
                                    <Button onClick={() => {
                                        setModal(true)
                                        setRoleId(item.id)
                                    }} sx={{ mt: '24px' }} color="custom_purple">Добавить</Button>
                                </Collapse>
                            </AccordionDetails>
                        </Accordion>
                    )
                })
            }
            <AddForm open={modal} setOpen={setModal} company_id={id} role_id={role_id} />
        </>
    )
}

export default Users