export const SET_COMPANYS = "SET_COMPANYS";
export const SET_COMPANY = "SET_COMPANY";
export const SET_COMPANY_USER = "SET_COMPANY_USER";
export const SET_COMPANY_TYPE = "SET_COMPANY_TYPE";
export const SET_COMPANY_ROLES = "SET_COMPANY_ROLES";
export const SET_COMPANY_MACHINE = "SET_COMPANY_MACHINE";
export const SET_MACHINE_TYPE = "SET_MACHINE_TYPE";
export const SET_MACHINE_CLASS = "SET_MACHINE_CLASS";
export const SET_MACHINE_MODAL = "SET_MACHINE_MODAL";
export const SET_MACHINE_GEN = "SET_MACHINE_GEN";
export const SET_MACHINE_WORRANTY = "SET_MACHINE_WORRANTY";
export const SET_MACHINE_TYPES = "SET_MACHINE_TYPES";
export const SET_USER_ENTRY = "SET_USER_ENTRY";
export const SET_SERCH_MACHINE = "SET_SERCH_MACHINE";
export const SET_REG_REQUESTS = "SET_REG_REQUESTS";
export const SET_ADD_MACHINE_REQUESTS = "SET_ADD_MACHINE_REQUESTS";
export const SET_MACHINES_WITHOUT_COMPANY = "SET_MACHINES_WITHOUT_COMPANY";
export const SET_FIND_COMPANY = "SET_FIND_COMPANY";
