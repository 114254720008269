import { toast } from "react-toastify";
import api from "../../services/api";
import axios from "axios";

import {
  SET_COMPANY,
  SET_COMPANYS,
  SET_COMPANY_MACHINE,
  SET_COMPANY_ROLES,
  SET_COMPANY_TYPE,
  SET_COMPANY_USER,
  SET_MACHINE_CLASS,
  SET_MACHINE_GEN,
  SET_MACHINE_MODAL,
  SET_MACHINE_TYPE,
  SET_MACHINE_TYPES,
  SET_MACHINE_WORRANTY,
  SET_REG_REQUESTS,
  SET_ADD_MACHINE_REQUESTS,
  SET_SERCH_MACHINE,
  SET_USER_ENTRY,
  SET_FIND_COMPANY,
  SET_MACHINES_WITHOUT_COMPANY,
} from "./types";

export const setCompanys = (data) => {
  return {
    type: SET_COMPANYS,
    payload: data,
  };
};

export const getCompanys =
  (id = 0) =>
  async (dispatch, getState) => {
    try {
      const type = id !== 0 ? `?type=${id}` : "";
      const response = await api.get(`/api/company${type}`, {});

      if (response.status === 200) {
        const json = await response.data;
        dispatch(setCompanys(json));
      }
    } catch (e) {
      console.error(e);
    }
  };

export const setCompany = (data) => {
  return {
    type: SET_COMPANY,
    payload: data,
  };
};

export const getCompany = (id) => async (dispatch, getState) => {
  try {
    const response = await api.get(`/api/company/${id}`, {});

    if (response.status === 200) {
      const json = await response.data;
      dispatch(setCompany(json));
    }
  } catch (e) {
    console.error(e);
  }
};

export const setCompanyUser = (data) => {
  return {
    type: SET_COMPANY_USER,
    payload: data,
  };
};

export const getCompanyUser = (id, role_id) => async (dispatch, getState) => {
  try {
    const qRole_id = typeof role_id !== "undefined" ? `?role=${role_id}` : "";
    const response = await api.get(`/api/company/${id}/users${qRole_id}`, {});

    if (response.status === 200) {
      const json = await response.data;
      dispatch(setCompanyUser(json));
    }
  } catch (e) {
    console.error(e);
  }
};

export const setCompanyType = (data) => {
  return {
    type: SET_COMPANY_TYPE,
    payload: data,
  };
};

export const getCompanyType = (id) => async (dispatch, getState) => {
  try {
    const response = await api.get(`/api/company/${id}/type`, {});

    if (response.status === 200) {
      const json = await response.data;
      dispatch(setCompanyType(json));
    }
  } catch (e) {
    console.error(e);
  }
};

export const setCompanyRoles = (data) => {
  return {
    type: SET_COMPANY_ROLES,
    payload: data,
  };
};

export const getCompanyRoles = (id) => async (dispatch, getState) => {
  try {
    const response = await api.get(`/api/company/${id}/roles`, {});

    if (response.status === 200) {
      const json = await response.data;
      dispatch(setCompanyRoles(json));
    }
  } catch (e) {
    console.error(e);
  }
};

export const setCompanyMachine = (data) => {
  return {
    type: SET_COMPANY_MACHINE,
    payload: data,
  };
};

export const getCompanyMachine = (id) => async (dispatch, getState) => {
  try {
    const response = await api.get(`/api/v1/machines/company/${id}`, {});

    if (response.status === 200) {
      const json = await response.data;
      dispatch(setCompanyMachine(json));
    }
  } catch (e) {
    console.error(e);
  }
};

export const setType = (data) => {
  return {
    type: SET_MACHINE_TYPE,
    payload: data,
  };
};

export const getType = () => async (dispatch, getState) => {
  try {
    const response = await api.get("/api/v1/types");

    if (response.status === 200) {
      const json = await response.data;
      dispatch(setType(json));
    }
  } catch (e) {
    console.error(e);
  }
};

export const setClass = (data) => {
  return {
    type: SET_MACHINE_CLASS,
    payload: data,
  };
};

export const getClass = () => async (dispatch, getState) => {
  try {
    const response = await api.get("/api/v1/classes");

    if (response.status === 200) {
      const json = await response.data;
      dispatch(setClass(json));
    }
  } catch (e) {
    console.error(e);
  }
};

export const setModal = (data) => {
  return {
    type: SET_MACHINE_MODAL,
    payload: data,
  };
};

export const getModal = () => async (dispatch, getState) => {
  try {
    const response = await api.get("/api/v1/models");

    if (response.status === 200) {
      const json = await response.data;
      dispatch(setModal(json));
    }
  } catch (e) {
    console.error(e);
  }
};

export const setGen = (data) => {
  return {
    type: SET_MACHINE_GEN,
    payload: data,
  };
};

export const getGen = () => async (dispatch, getState) => {
  try {
    const response = await api.get("/api/v1/gen");

    if (response.status === 200) {
      const json = await response.data;
      dispatch(setGen(json));
    }
  } catch (e) {
    console.error(e);
  }
};

export const setGenType = (data) => {
  return {
    type: SET_MACHINE_MODAL,
    payload: data,
  };
};

export const getModel = (id) => async (dispatch, getState) => {
  try {
    const response = await api.get(`/api/v1/classes/${id}`);

    if (response.status === 200) {
      const json = await response.data;
      dispatch(getGenType(json.type_id));
    }
  } catch (e) {
    console.error(e);
  }
};

export const getGenType = (id) => async (dispatch, getState) => {
  try {
    const response = await api.get(`/api/v1/gen?type=${id}`);

    if (response.status === 200) {
      const json = await response.data;
      dispatch(setGenType(json));
    }
  } catch (e) {
    console.error(e);
  }
};

export const setWorranty = (data) => {
  return {
    type: SET_MACHINE_WORRANTY,
    payload: data,
  };
};

export const getWorranty = () => async (dispatch, getState) => {
  try {
    const response = await api.get("/api/v1/warranty");

    if (response.status === 200) {
      const json = await response.data;
      dispatch(setWorranty(json));
    }
  } catch (e) {
    console.error(e);
  }
};

export const setUserEntry = (data) => {
  return {
    type: SET_USER_ENTRY,
    payload: data,
  };
};

export const addCompany = (item) => async (dispatch, getState) => {
  try {
    const response = await api.post("/api/company", item);

    if (response.status === 201) {
      const json = await response.data;
      dispatch(setUserEntry(json));
      dispatch(getCompanys());
    }
  } catch (e) {
    console.error(e);
  }
};

export const setFindCompany = (data) => {
  return {
    type: SET_FIND_COMPANY,
    payload: data,
  };
};

export const findCompanyByInn = (inn) => async (dispatch, getState) => {
  try {
    const apiUrl =
      "https://suggestions.dadata.ru/suggestions/api/4_1/rs/findById/party";
    const token = "ef2de6cedda7083ee1f6b522dd3356ec9bc3dbcf";
    const body = {
      query: inn,
      branch_type: "MAIN",
    };
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Token ${token}`,
    };
    axios
      .post(apiUrl, body, { headers })
      .then((resp) => {
        if (
          typeof resp.data.suggestions !== "undefined" &&
          resp.data.suggestions.length > 0
        ) {
          const find = {
            title: resp.data.suggestions[0].value,
            inn: resp.data.suggestions[0].data.inn,
            kpp: resp.data.suggestions[0].data.kpp,
            address:
              resp.data.suggestions[0].data.address !== null
                ? resp.data.suggestions[0].data.address.value
                : "",
            name:
              resp.data.suggestions[0].data.management !== null
                ? resp.data.suggestions[0].data.management.name
                : "",
          };
          dispatch(setFindCompany(find));
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
    /* const response = await api.post("/api/company", item);
  
      if (response.status === 201) {
        const json = await response.data;
        dispatch(getCompanys());
      } */
  } catch (e) {
    console.error(e);
  }
};

export const addUser = (item) => async (dispatch, getState) => {
  try {
    const response = await api.post("/api/register", item);

    if (response.status === 201) {
      dispatch(getCompanyUser(item.company_id, item.role_id));
      toast.success("Пользователь добавлен");
    }
  } catch (e) {
    switch (e.response.data.message) {
      case "You must fill all the fields":
        toast.error("Необходимо заполнить все поля");
        break;
      case "You must enter a valid email":
        toast.error("Некорректный email");
        break;
      case "Password should be min 6 character":
        toast.error("Пароль должен иметь более 6 символов");
        break;
      case "The user exists and is connected to another company":
        toast.error("Пользователь уже существует в другой компании");
        break;
      default:
        toast.error("Ошибка добавления");
        break;
    }
  }
};

export const updateCompany = (id, item) => async (dispatch, getState) => {
  try {
    const response = await api.put(`/api/company/${id}`, item);

    if (response.status === 200) {
      dispatch(getCompany(id));
      dispatch(getCompanyType(id));
      toast.success("Успешно обновлено");
    }
  } catch (e) {
    console.error(e);
  }
};

export const setCompanyTypes = (data) => {
  return {
    type: SET_MACHINE_TYPES,
    payload: data,
  };
};

export const getCompanyTypes = () => async (dispatch, getState) => {
  try {
    const response = await api.get(`/api/company/type`, {});

    if (response.status === 200) {
      const json = await response.data;
      dispatch(setCompanyTypes(json));
    }
  } catch (e) {
    console.error(e);
  }
};

export const setSerchMachine = (data) => {
  return {
    type: SET_SERCH_MACHINE,
    payload: data,
  };
};

export const getSerchMachine = (nomer) => async (dispatch, getState) => {
  try {
    const response = await api.get(`/api/v1/machines/${nomer}/params`, {});

    if (response.status === 200) {
      const json = await response.data;
      dispatch(setSerchMachine(json));
    }
  } catch (e) {
    console.error(e);
  }
};

export const linkMachine = (item) => async (dispatch, getState) => {
  try {
    const response = await api.post("/api/v1/machine/company/access", item);

    if (response.status === 201) {
      dispatch(getCompanyMachine(item.company_id));
    }
  } catch (e) {
    console.error(e);
  }
};

export const unlinkMachine = (id, nomer) => async (dispatch, getState) => {
  try {
    const response = await api.delete(
      `/api/v1/machine/company/${id}/access/${nomer}`
    );

    if (response.status === 200) {
      dispatch(getCompanyMachine(id));
      toast.success("Удалено");
    }
  } catch (e) {
    console.error(e);
  }
};

export const setRegRequests = (data) => {
  return {
    type: SET_REG_REQUESTS,
    payload: data,
  };
};

export const getRegReqests =
  (id = 0) =>
  async (dispatch, getState) => {
    try {
      const qid = id !== 0 ? `/${id}` : "";
      const response = await api.get(`/api/registration/request${qid}`, {});

      if (response.status === 200) {
        const json = await response.data;
        dispatch(setRegRequests(json));
      }
    } catch (e) {
      console.error(e);
    }
  };

export const setAddMachineRequests = (data) => {
  return {
    type: SET_ADD_MACHINE_REQUESTS,
    payload: data,
  };
};

export const getAddMachineRequests =
  (id = 0) =>
  async (dispatch, getState) => {
    try {
      const qid = id !== 0 ? `/${id}` : "";
      const response = await api.get(
        `/api/machine/add-machine-request${qid}`,
        {}
      );

      if (response.status === 200) {
        const json = await response.data;
        dispatch(setAddMachineRequests(json));
      }
    } catch (e) {
      console.error(e);
    }
  };

export const addMachineForCompany =
  (id, data) => async (dispatch, getState) => {
    try {
      const response = await api.post(`/api/company/addMachine/${id}`, data);

      if (response.status === 201) {
        const json = await response.data;
        toast.success("Машины успешно добавлены к компании.");
        dispatch(getAddMachineRequests(id));
      }
    } catch (e) {
      toast.error("Ошибка добавления машин.");
      console.error(e);
    }
  };

export const rejectionAddMachineReqest =
  (id, reason) => async (dispatch, getState) => {
    try {
      const response = await api.get(
        `/api/machine/add-machine-request/${id}/rejection?reason=${reason}`,
        {}
      );

      if (response.status === 200) {
        toast.success("Заявка успешно отказана.");
        dispatch(getAddMachineRequests(id));
      }
    } catch (e) {
      toast.error("Ошибка отправки отказа.");
      console.error(e);
    }
  };

export const setMachinesWithoutCompany = (data) => {
  return {
    type: SET_MACHINES_WITHOUT_COMPANY,
    payload: data,
  };
};

export const getMachinesWithoutCompany = () => async (dispatch, getState) => {
  try {
    const response = await api.get(`/api/v1/machine/machine-without-company`);

    if (response.status === 200) {
      const json = await response.data;
      dispatch(setMachinesWithoutCompany(json));
    }
  } catch (e) {
    console.error(e);
  }
};

export const createForRequest = (id, type) => async (dispatch, getState) => {
  try {
    const response = await api.post(`/api/company/${id}`, {
      type,
    });

    if (response.status === 201) {
      const json = await response.data;
      toast.success("Компания успешно добавлена.");
      //dispatch(setRegRequests(json))
    }
  } catch (e) {
    console.error(e);
  }
};

export const rejectionRegReqests =
  (id, reason) => async (dispatch, getState) => {
    try {
      const response = await api.get(
        `/api/registration/request/${id}/rejection?reason=${reason}`,
        {}
      );

      if (response.status === 200) {
        dispatch(getRegReqests(id));
      }
    } catch (e) {
      console.error(e);
    }
  };
