import { Fab, IconButton, ListItem } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { DialogAction, Table } from '../../components'
import { getClass, getCompanyMachine, getGen, getModal, getType, getWorranty, setClass, setCompanyMachine, setGen, setModal, setType, setWorranty, unlinkMachine } from '../../store/Companys/actions'
import * as CompanySelectors from "../../store/Companys/selectors"
import DoneIcon from '@mui/icons-material/Done'
import AddIcon from '@mui/icons-material/Add'
import AddForm from './AddForm'
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import DeleteForeverTwoToneIcon from '@mui/icons-material/DeleteForeverTwoTone';

function Machines() {
    const { id } = useParams()
    const dispatch = useDispatch()

    const machines = useSelector(CompanySelectors.machines)
    const type = useSelector(CompanySelectors.machineType)
    const classList = useSelector(CompanySelectors.classList)
    const modal = useSelector(CompanySelectors.modal)
    const gen = useSelector(CompanySelectors.gen)
    const worranty = useSelector(CompanySelectors.worranty)

    const [add, setAdd] = useState(false)
    const [deleteDialog, setDeleteDialog] = useState(false)
    const [item, setItem] = useState(null)

    useEffect(() => {
        dispatch(getCompanyMachine(id))
        dispatch(getType())
        dispatch(getClass())
        dispatch(getModal())
        dispatch(getGen())
        dispatch(getWorranty())

        return () => {
            dispatch(setCompanyMachine([]))
            dispatch(setType([]))
            dispatch(setClass([]))
            dispatch(setModal([]))
            dispatch(setGen([]))
            dispatch(setWorranty([]))
        }
    }, [])

    const owner = (item) => {
        return item.owner === 1 ? <DoneIcon sx={{ color: "#219653" }} /> : <HorizontalRuleIcon sx={{ color: "#00000061" }} />
    }

    const deleteMachineHandle = (id, nomer) => {
        dispatch(unlinkMachine(id, nomer))
    }

    const openDeleteDialogHandle = (nomer) => {
        setItem(nomer)
        setDeleteDialog(true)
    }

    const del = (item) => {
        return (
            <IconButton color='error' onClick={() => openDeleteDialogHandle(item.nomer)}>
                <DeleteForeverTwoToneIcon />
            </IconButton>
        )
    }

    const cols = [
        { title: "Машина", code: 'nomer', type: 'link' },
        { title: 'Тип', code: 'type_id', vars: 'type' },
        { title: 'Класс', code: 'classes_id', vars: 'classList' },
        { title: 'Модель', code: 'model', vars: 'modal' },
        { title: 'Поколение', code: 'generation', vars: 'gen' },
        { title: 'Гарантия', code: 'warranty', vars: 'worranty' },
        { title: 'Доступна до', code: 'black_date', type: 'date' },
        { title: 'Владелец', val: owner },
        { title: '', val: del }
    ]

    const parans = {
        type,
        classList,
        modal,
        gen,
        worranty
    }
    return (
        <>
            <Table cols={cols} data={machines} params={parans} />
            <Fab color='custom_purple' aria-label="add" sx={{
                position: 'fixed',
                bottom: '50px',
                right: '50px'
            }}
                onClick={() => setAdd(true)}
            >
                <AddIcon />
            </Fab>
            <AddForm open={add} setOpen={setAdd} id={parseInt(id)} />
            <DialogAction open={deleteDialog} setOpen={setDeleteDialog} timer={3} title='Удаление доступа к машине' text="Вы действительно хотите удалить доступ к машине у этой компании?" agree="Удалить" action={() => deleteMachineHandle(parseInt(id), item)} />
        </>
    )
}

export default Machines