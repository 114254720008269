import React from "react";
import * as CompanySelectors from "../../store/Companys/selectors";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "../../components";
import { Container } from "@mui/material";
import { useEffect } from "react";
import { getAddMachineRequests } from "../../store/Companys/actions";
import { useNavigate } from "react-router-dom";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

function AddMachineRequest() {
  const addMachineRequests = useSelector(CompanySelectors.addMachineRequests);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getAddMachineRequests());
  }, []);

  const id = (item) => {
    return (
      <span onClick={() => next(item)} style={{ cursor: "pointer" }}>
        {item.id}
      </span>
    );
  };

  const next = (item) => {
    navigate(`/add-machine-requests/${item.id}`);
  };

  const succcess = (item) => {
    return item.success === 1 ? (
      <CheckCircleRoundedIcon color="success" />
    ) : item.success === 2 ? (
      <HighlightOffIcon color="error" />
    ) : null;
  };

  const cols = [
    { title: "#", val: id },
    { title: "Название", code: "company_title" },
    { title: "ИНН", code: "company_inn" },
    { title: "КПП", code: "company_kpp" },
    { title: "Адрес", code: "company_address" },
    { title: "ФИО", code: "user_name" },
    { title: "Email", code: "user_email" },
    { title: "Телефон", code: "user_phone" },
    { title: "Обработано", val: succcess },
  ];

  return (
    <Container maxWidth="xl" sx={{ mt: 2 }}>
      <Table
        cols={cols}
        data={
          typeof addMachineRequests === "object" && addMachineRequests.list
            ? addMachineRequests.list
            : []
        }
      />
    </Container>
  );
}

export default AddMachineRequest;
